import './CommitteeContent.css';

import Manish from '../assets/committee/manish-chand.jpg';
import Luigi from '../assets/committee/luigi-bonnie.jpg';
import Antonino from '../assets/committee/antonino-spinelli.jpg';
import Delia from '../assets/committee/delia-cortes-guiral.jpg';
import Mohamed from '../assets/committee/mohamed-hassouna.jpg';

const CommitteeContent = (props) => {
    const { teamMember } = props;

    let committeeBio;

    switch (teamMember) {
        case 'manish':
            committeeBio = (
                <div className="committee-bio-content">
                    <img className="team-img-modal" src={Manish} alt="manish"></img>

                    <div>
                        <p>
                            Professor Chand is a Consultant Colorectal Surgeon at
                            University College London Hospitals. He is a Key Opinion
                            Leader and recognised expert in laparoscopic and robotic
                            colorectal surgery and imaging of rectal cancer working
                            closely with innovative digital surgery research.
                        </p>

                        <p>
                            Prof Chand&rsquo;s research interests are in surgical
                            technology which include image-guided surgery, fluorescence,
                            AI and Augmented Reality. He is active member of several
                            surgical societies, especially{' '}
                            <a href="https://isfgs.org/" target="_blank">
                                ISFGS
                            </a>
                            ,{' '}
                            <a href="https://eaes.eu/" target="_blank">
                                EAES
                            </a>{' '}
                            and{' '}
                            <a href="https://www.facs.org/" target="_blank">
                                ACS
                            </a>{' '}
                            Technology committees. He has published over 200 peer-reviewed
                            articles with an H-index of 33 and continue to research into
                            these areas and their integration into the next generation
                            robotic platforms. His team has an excellent record on
                            'first-in-man' studies and firmly believe in enhancing cutting
                            edge technology for patient care.
                        </p>
                    </div>
                </div>
            );
            break;

        case 'luigi':
            committeeBio = (
                <div className="committee-bio-content">
                    <img className="team-img-modal" src={Luigi} alt="luigi"></img>

                    <div>
                        <p>
                            Professor Boni is the Chief of the Department of Surgery and
                            Emergency at the IRCCS - Ca' Granda - Policlinico Hospital and
                            full Professor of Surgery at the University of Milan.
                        </p>

                        <p>
                            He is currently the President of the EU chapter of the
                            International Society of Image Guided Surgery and member of
                            the European Surgical Association (ESA) and the American
                            College of Surgeon, as well as Honorary Member of the
                            Association of Laparoscopic Surgeon of Great Britain and
                            Ireland (ALSGBI). Also, an active member of EAES Technology
                            committee, leading the image guided surgery sub-committee. He
                            has more than 270 scientific publications on different topics
                            in peer reviewed, indexed journals and more than 25 chapters
                            in surgical book.
                        </p>
                    </div>
                </div>
            );
            break;

        case 'antonino':
            committeeBio = (
                <div className="committee-bio-content">
                    <img className="team-img-modal" src={Antonino} alt="antonino"></img>

                    <div>
                        <p>
                            Professor Spinelli is the Director of the Divisione of Colon
                            and Rectal Surgey, Co- Director of IBD Centre and Full
                            Professor at Humanitas, Rozzano, Milan.
                        </p>

                        <p>
                            He serves as General Secretary and President in waiting of
                            European Society of Coloproctology, ESCP.
                        </p>

                        <p>
                            He has editor appointments in several interested scientific
                            journal in the field of colorectal surgery.
                        </p>

                        <p>
                            He is member of ESA, ASCRS, IOIBD, ECCO, ESSO and other
                            Scientific Societies and he is Honorary Fellow of the Czech
                            Society of Surgery and Honorary Member of the Brazilian
                            College of Digestive Surgery.
                        </p>

                        <p>
                            Prof. Spinelli authored / co-authored 330 Pubmed indexed
                            papers with a H index of 49 (Scopus).
                        </p>
                    </div>
                </div>
            );
            break;

        case 'delia':
            committeeBio = (
                <div className="committee-bio-content">
                    <img className="team-img-modal" src={Delia} alt="delia"></img>

                    <div>
                        <p>
                            Prof. Cort&eacute;s is a Consultant Surgical oncologist. She
                            is a specialist in peritoneal surface malignancies, certified
                            and first promotion of European School of Peritoneal Surface
                            Oncology. Specialist in Cytoreductive Surgery, HIPEC
                            (hiperthermic intraperioneal chemotherapy) and PIPAC
                            (pressurized aerosolized intraperitoneal chemotherapy).
                        </p>

                        <p>
                            Prof. Cort&eacute;s has been in charge of @SoMe4Peritoneum
                            Twitter community since 2019 and she is really committed to
                            globalization of surgical knowledge through social media and
                            educational channels.
                        </p>

                        <p>
                            Her research interests are in Peritoneal Surface Malignancies
                            (HIPEC &amp; PIPAC), Colo-rectal Surgery, Minimally Invasive
                            Surgery, Fluorescence, and Pre-habilitation
                        </p>
                    </div>
                </div>
            );
            break;

        case 'mohamed':
            committeeBio = (
                <div className="committee-bio-content">
                    <img className="team-img-modal" src={Mohamed} alt="mohamed"></img>

                    <div>
                        <p>
                            Dr. Hassouna is a Clinical Lecturer and an MSc programme
                            leader at Division of Surgery and Interventional Science,
                            University College London (UCL).
                        </p>

                        <p>
                            Dr. Hassouna is a surgical trainee and currently pursuing his
                            PhD. His research interests are in fluorescence guided and
                            multispectral imaging surgery and modern surgical education
                            and training.
                        </p>
                    </div>
                </div>
            );
            break;
    }

    return <div className="committee-bio-content">{committeeBio}</div>;
};

export default CommitteeContent;
