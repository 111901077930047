import { Link } from 'react-router-dom';

import Avatar from '../../shared/components/UIElements/Avatar';
import Card from '../../shared/components/UIElements/Card';
import profilePicPlaceholder from '../../assets/profile-pic-placeholder.jpeg';

import './UserItem.css';

const UserItem = (props) => {
    const { username, displayName } = props;

    return (
        <li className="user-item">
            <Card className="user-item__content">
                <Link to={`/${username}/media`}>
                    <div className="user-item__image">
                        <Avatar image={profilePicPlaceholder} alt={username} />
                    </div>

                    <div className="user-item__info">
                        <h2>{displayName}</h2>
                        <span>See media</span>
                    </div>
                </Link>
            </Card>
        </li>
    );
};

export default UserItem;
