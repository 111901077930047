import Card from '../../shared/components/UIElements/Card';
import MediaItem from './MediaItem';
import Button from '../../shared/components/FormElements/Button';

import './MediaList.css';

const MediaList = (props) => {
    const { items, onDeleteMediaItem } = props;

    if (items.length === 0) {
        return (
            <div className="media-list center">
                <Card>
                    <h2>No media items found. Maybe create one?</h2>

                    <Button to="/media/new">Share Media</Button>
                </Card>
            </div>
        );
    }

    return (
        <ul className="media-list">
            {items.map((item) => (
                <MediaItem
                    key={item.sk}
                    id={item.sk}
                    title={item.MediaName}
                    description={item.MediaDescription}
                    fileLocation={item.FileLocation}
                    s3Filename={item.S3Filename}
                    presignedUrl={item.presignedUrl}
                    fileType={item.FileType}
                    creator={item.pk}
                    onDelete={onDeleteMediaItem}
                />
            ))}
        </ul>
    );
};

export default MediaList;
