import React, { useState, useContext } from 'react';
import { Link } from 'react-router-dom';

import Card from '../../shared/components/UIElements/Card';
import Input from '../../shared/components/FormElements/Input';
import Button from '../../shared/components/FormElements/Button';
import ErrorModal from '../../shared/components/UIElements/ErrorModal';
import LoadingSpinner from '../../shared/components/UIElements/LoadingSpinner';
import {
    VALIDATOR_EMAIL,
    VALIDATOR_MINLENGTH,
    VALIDATOR_MAXLENGTH,
    VALIDATOR_REQUIRE,
    VALIDATOR_LOWERCASE,
    VALIDATOR_SPECIAL_CHARACTER,
} from '../../shared/util/validators';
import { useForm } from '../../shared/hooks/form-hook';
import { useHttpClient } from '../../shared/hooks/http-hook';
import { AuthContext } from '../../shared/context/auth-context';

import './Auth.css';

const Auth = () => {
    const auth = useContext(AuthContext);
    const [isLoginMode, setIsLoginMode] = useState(true);
    const { isLoading, error, setError, sendRequest, clearError } = useHttpClient();

    const [formState, inputHandler, setFormData] = useForm(
        {
            username: {
                value: '',
                isValid: false,
            },
            password: {
                value: '',
                isValid: false,
            },
        },
        false
    );

    const switchModeHandler = () => {
        if (!isLoginMode) {
            setFormData(
                {
                    ...formState.inputs,
                    email: undefined,
                    passwordConfirm: undefined,
                },
                formState.inputs.email.isValid &&
                    formState.inputs.password.isValid &&
                    formState.inputs.passwordConfirm.isValid
            );
        } else {
            setFormData(
                {
                    ...formState.inputs,
                },
                false
            );
        }
        setIsLoginMode((prevMode) => !prevMode);
    };

    const authSubmitHandler = async (event) => {
        event.preventDefault();

        if (isLoginMode) {
            try {
                const responseData = await sendRequest(
                    `${process.env.REACT_APP_BACKEND_URL}/auth/login`,
                    'POST',
                    JSON.stringify({
                        username: formState.inputs.username.value.trim(),
                        password: formState.inputs.password.value,
                    }),
                    {
                        'Content-Type': 'application/json',
                    }
                );

                auth.login(
                    responseData.userId,
                    responseData.token,
                    responseData.username,
                    responseData.displayName,
                    responseData.isAdmin
                );
            } catch (err) {
                console.log(err);
            }
        } else {
            if (
                formState.inputs.password.value !== formState.inputs.passwordConfirm.value
            ) {
                setError('Passwords do not match.');
                return;
            }

            try {
                const responseData = await sendRequest(
                    `${process.env.REACT_APP_BACKEND_URL}/auth/signup`,
                    'POST',
                    JSON.stringify({
                        email: formState.inputs.email.value.trim(),
                        username: formState.inputs.username.value.trim(),
                        password: formState.inputs.password.value,
                    }),
                    {
                        'Content-Type': 'application/json',
                    }
                );

                auth.login(
                    responseData.userId,
                    responseData.token,
                    responseData.username,
                    responseData.displayName,
                    responseData.isAdmin
                );
            } catch (err) {
                console.log(err);
            }
        }
    };

    return (
        <>
            <ErrorModal error={error} onClear={clearError} />

            <Card className="authentication">
                {isLoading && <LoadingSpinner asOverlay />}

                <h2>Login Required</h2>

                <hr />

                <form onSubmit={authSubmitHandler}>
                    {!isLoginMode && (
                        <Input
                            element="input"
                            id="email"
                            type="text"
                            label="Email"
                            validators={[VALIDATOR_EMAIL()]}
                            errorText="Please enter a valid email."
                            onInput={inputHandler}
                            autoComplete={'on'}
                        />
                    )}

                    <Input
                        element="input"
                        id="username"
                        label="Username"
                        validators={[
                            VALIDATOR_REQUIRE(),
                            VALIDATOR_MINLENGTH(3),
                            VALIDATOR_MAXLENGTH(30),
                        ]}
                        errorText="Please enter a valid username with at least 3 characters"
                        onInput={inputHandler}
                        autoComplete={'off'}
                    />

                    <Input
                        element="input"
                        id="password"
                        type="password"
                        label="Password"
                        validators={[
                            VALIDATOR_MINLENGTH(8),
                            VALIDATOR_LOWERCASE(),
                            VALIDATOR_SPECIAL_CHARACTER(),
                        ]}
                        errorText="Please enter a valid password, at least 8 characters and at least one special character."
                        onInput={inputHandler}
                        autoComplete={'off'}
                    />

                    {!isLoginMode && (
                        <Input
                            element="input"
                            id="passwordConfirm"
                            type="password"
                            label="Password Confirm"
                            validators={[]}
                            errorText=""
                            onInput={inputHandler}
                            autoComplete={'off'}
                        />
                    )}

                    <Button type="submit" disabled={!formState.isValid}>
                        {isLoginMode ? 'LOGIN' : 'SIGNUP'}
                    </Button>
                </form>

                <Button inverse onClick={switchModeHandler}>
                    SWITCH TO {isLoginMode ? 'SIGNUP' : 'LOGIN'}
                </Button>

                {isLoginMode && (
                    <div className="forgot-password-btn">
                        <Link to="/forgot-password">Forgot password</Link>
                    </div>
                )}
            </Card>
        </>
    );
};

export default Auth;
