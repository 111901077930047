import React, { useContext } from 'react';
import { NavLink } from 'react-router-dom';

import { AuthContext } from '../context/auth-context';

import './NavLinks.css';

const NavLinks = () => {
    const auth = useContext(AuthContext);

    const logoutHandler = () => {
        auth.logout();
    };

    return (
        <nav className="nav-links">
            <ul>
                <li>
                    <NavLink
                        to="/"
                        className={(navData) => (navData.isActive ? ' active' : '')}
                    >
                        Home
                    </NavLink>
                </li>

                <li>
                    <NavLink
                        to="/committee"
                        className={(navData) => (navData.isActive ? ' active' : '')}
                    >
                        Committee
                    </NavLink>
                </li>

                <li>
                    <NavLink
                        to="/news"
                        className={(navData) => (navData.isActive ? ' active' : '')}
                    >
                        News
                    </NavLink>
                </li>

                {auth.isAdmin && (
                    <li>
                        <NavLink
                            to="/media"
                            end
                            className={(navData) => (navData.isActive ? ' active' : '')}
                        >
                            All Media
                        </NavLink>
                    </li>
                )}

                {auth.isAdmin && (
                    <li>
                        <NavLink
                            to={`/users`}
                            className={(navData) => (navData.isActive ? ' active' : '')}
                        >
                            All Users
                        </NavLink>
                    </li>
                )}

                {auth.isLoggedIn && (
                    <li>
                        <NavLink
                            to={`/${auth.username}/media`}
                            className={(navData) => (navData.isActive ? ' active' : '')}
                        >
                            My Media
                        </NavLink>
                    </li>
                )}

                {auth.isLoggedIn && (
                    <li>
                        <NavLink
                            to="/media/new"
                            className={(navData) => (navData.isActive ? ' active' : '')}
                        >
                            Add Media
                        </NavLink>
                    </li>
                )}

                {!auth.isLoggedIn && (
                    <li>
                        <NavLink
                            to="/auth"
                            className={(navData) => (navData.isActive ? ' active' : '')}
                        >
                            Login / Register
                        </NavLink>
                    </li>
                )}

                {auth.isLoggedIn && (
                    <li>
                        <div className="logout-btn" onClick={() => logoutHandler()}>
                            Logout
                        </div>
                    </li>
                )}
            </ul>
        </nav>
    );
};

export default NavLinks;
