import React, { useEffect, useState, useContext } from 'react';
import { useParams } from 'react-router-dom';

import MediaList from '../components/MediaList';
import ErrorModal from '../../shared/components/UIElements/ErrorModal';
import LoadingSpinner from '../../shared/components/UIElements/LoadingSpinner';
import { useHttpClient } from '../../shared/hooks/http-hook';
import { AuthContext } from '../../shared/context/auth-context';

const UserMedia = () => {
    const [loadedMedia, setLoadedMedia] = useState();
    const { isLoading, error, sendRequest, clearError } = useHttpClient();
    const auth = useContext(AuthContext);
    const username = useParams().username;

    useEffect(() => {
        const fetchMedia = async () => {
            try {
                const responseData = await sendRequest(
                    `${process.env.REACT_APP_BACKEND_URL}/media/user/${username}`,
                    'GET',
                    null,
                    {
                        Authorization: 'Bearer ' + auth.token,
                    }
                );

                setLoadedMedia(responseData);
            } catch (err) {}
        };
        fetchMedia();
    }, [sendRequest, username]);

    const mediaDeletedHandler = (deletedMediaItemId) => {
        setLoadedMedia((prevMediaItem) =>
            prevMediaItem.filter((mediaItem) => mediaItem.sk !== deletedMediaItemId)
        );
    };

    return (
        <>
            <ErrorModal error={error} onClear={clearError} />

            {isLoading && (
                <div className="center">
                    <LoadingSpinner asOverlay />
                </div>
            )}

            {!isLoading && loadedMedia && (
                <MediaList items={loadedMedia} onDeleteMediaItem={mediaDeletedHandler} />
            )}
        </>
    );
};

export default UserMedia;
