import './News.css';

const News = () => {
    return (
        <div className="news-container">
            <div className="news-header">
                <h1 className="news-title">Latest news</h1>

                <div className="divider"></div>
            </div>

            <div className="news-body">
                <p>
                    <a href="https://eaes.eu/eaes-annual-congress/" target="_blank">
                        31st International EAES Congress | Roma, Italy | 20 - 23 June 2023
                    </a>
                </p>

                <p>
                    <a href="https://events.ortra.com/gsos2024/Program" target="_blank">
                        Global Surgical Oncology Summit 2024 (GSOS)
                    </a>
                </p>
            </div>

            <div className="news-footer"></div>
        </div>
    );
};

export default News;
