import React, { useState } from 'react';
import { useSearchParams, Link } from 'react-router-dom';

import Card from '../../shared/components/UIElements/Card';
import Input from '../../shared/components/FormElements/Input';
import Button from '../../shared/components/FormElements/Button';
import ErrorModal from '../../shared/components/UIElements/ErrorModal';
import LoadingSpinner from '../../shared/components/UIElements/LoadingSpinner';
import {
    VALIDATOR_MINLENGTH,
    VALIDATOR_REQUIRE,
    VALIDATOR_LOWERCASE,
    VALIDATOR_SPECIAL_CHARACTER,
} from '../../shared/util/validators';
import { useForm } from '../../shared/hooks/form-hook';
import { useHttpClient } from '../../shared/hooks/http-hook';

import './Auth.css';
import './PasswordReset.css';

const PasswordReset = () => {
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);
    const [searchParams] = useSearchParams();

    const { isLoading, error, setError, sendRequest, clearError } = useHttpClient();

    const [formState, inputHandler] = useForm(
        {
            password: {
                value: '',
                isValid: false,
            },
        },
        false
    );

    const passwordResetSubmitHandler = async (event) => {
        event.preventDefault();

        if (formState.inputs.password.value !== formState.inputs.passwordConfirm.value) {
            setError('Passwords do not match.');
            return;
        }

        try {
            const responseData = await sendRequest(
                `${process.env.REACT_APP_BACKEND_URL}/auth/passwordReset`,
                'POST',
                JSON.stringify({
                    password: formState.inputs.password.value,
                    token: searchParams.get('token'),
                    username: searchParams.get('username'),
                    email: searchParams.get('email'),
                    userId: searchParams.get('userId'),
                }),
                {
                    'Content-Type': 'application/json',
                }
            );

            setShowSuccessMessage(true);
        } catch (err) {
            console.log(err);
        }
    };

    if (showSuccessMessage) {
        return (
            <div className="password-reset-success-message">
                <div>You have successfully reset your password</div>
                <div>
                    <Link to="/auth">Go to login page</Link>
                </div>
            </div>
        );
    }

    return (
        <>
            <ErrorModal error={error} onClear={clearError} />

            <Card className="authentication">
                {isLoading && <LoadingSpinner asOverlay />}

                <h2>Reset password form</h2>
                <div>
                    Please enter your new password. You will be sent an email to confirm
                    that the process was successful.
                </div>

                <hr />

                <form onSubmit={passwordResetSubmitHandler}>
                    <Input
                        element="input"
                        id="password"
                        type="password"
                        label="New password"
                        validators={[
                            VALIDATOR_MINLENGTH(8),
                            VALIDATOR_LOWERCASE(),
                            VALIDATOR_SPECIAL_CHARACTER(),
                        ]}
                        errorText="Please enter a valid password, at least 8 characters and at least one special character."
                        onInput={inputHandler}
                        autoComplete={'off'}
                    />

                    <Input
                        element="input"
                        id="passwordConfirm"
                        type="password"
                        label="New password Confirm"
                        validators={[]}
                        errorText=""
                        onInput={inputHandler}
                        autoComplete={'off'}
                    />

                    <Button type="submit" disabled={!formState.isValid}>
                        Submit
                    </Button>
                </form>
            </Card>
        </>
    );
};

export default PasswordReset;
