import React, { useEffect, useState } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

import Layout from './shared/layout/Layout';
import NotFound from './NotFound';
import Home from './home/Home';
import Committee from './committee/Committee';
import News from './news/News';
import Users from './user/pages/Users';
import AllMedia from './userMedia/pages/AllMedia';
import NewMedia from './userMedia/pages/NewMedia';
import UserMedia from './userMedia/pages/UserMedia';
import UpdateMedia from './userMedia/pages/UpdateMedia';
import Auth from './user/pages/Auth';
import ForgotPassword from './user/pages/ForgotPassword';
import PasswordReset from './user/pages/PasswordReset';

import { AuthContext } from './shared/context/auth-context';
import { useAuth } from './shared/hooks/auth-hook';

import './App.css';

const App = () => {
    const { token, login, logout, userId, username, displayName, isAdmin } = useAuth();

    let routes;
    if (token) {
        routes = (
            <Routes>
                <Route path="/auth" element={<Navigate replace to="/" />} />

                <Route path="/" element={<Home />} />

                <Route path="/committee" element={<Committee />} />

                <Route path="/news" element={<News />} />

                {isAdmin && (
                    <>
                        <Route path="/media" element={<AllMedia />} />
                        <Route path="/users" element={<Users />} />
                    </>
                )}

                <Route path="/:username/media" element={<UserMedia />} />

                <Route path="/media/new" element={<NewMedia />} />

                <Route
                    path="/media/update/:username/:mediaItemId"
                    element={<UpdateMedia />}
                />

                <Route path="*" element={<NotFound />} />
            </Routes>
        );
    } else {
        routes = (
            <Routes>
                <Route path="/" element={<Home />} />

                <Route path="/committee" element={<Committee />} />

                <Route path="/news" element={<News />} />

                <Route path="/auth" element={<Auth />} />

                <Route path="/forgot-password" element={<ForgotPassword />} />

                <Route path="/password-reset" element={<PasswordReset />} />

                <Route path="*" element={<NotFound />} />
            </Routes>
        );
    }

    return (
        <AuthContext.Provider
            value={{
                isLoggedIn: !!token,
                token: token,
                userId: userId,
                username: username,
                displayName: displayName,
                isAdmin: isAdmin,
                login: login,
                logout: logout,
            }}
        >
            <Layout>{routes}</Layout>
        </AuthContext.Provider>
    );
};

export default App;
