import React, { useEffect, useState, useContext } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import Input from '../../shared/components/FormElements/Input';
import Button from '../../shared/components/FormElements/Button';
import Card from '../../shared/components/UIElements/Card';
import LoadingSpinner from '../../shared/components/UIElements/LoadingSpinner';
import ErrorModal from '../../shared/components/UIElements/ErrorModal';
import { VALIDATOR_REQUIRE, VALIDATOR_MINLENGTH } from '../../shared/util/validators';
import { useForm } from '../../shared/hooks/form-hook';
import { useHttpClient } from '../../shared/hooks/http-hook';
import { AuthContext } from '../../shared/context/auth-context';

import './MediaForm.css';

const UpdateMedia = () => {
    const auth = useContext(AuthContext);
    const { isLoading, error, sendRequest, clearError } = useHttpClient();
    const [loadedMediaItem, setLoadedMediaItem] = useState();
    const username = useParams().username;
    const mediaItemId = useParams().mediaItemId;
    const navigate = useNavigate();

    const [formState, inputHandler, setFormData] = useForm(
        {
            title: {
                value: '',
                isValid: false,
            },
            description: {
                value: '',
                isValid: false,
            },
        },
        false
    );

    useEffect(() => {
        const fetchMediaItem = async () => {
            try {
                const responseData = await sendRequest(
                    `${process.env.REACT_APP_BACKEND_URL}/media/${username}/${mediaItemId}`,
                    'GET',
                    null,
                    {
                        Authorization: 'Bearer ' + auth.token,
                    }
                );

                const mediaItem = responseData.Items[0];

                setLoadedMediaItem(mediaItem);

                setFormData(
                    {
                        title: {
                            value: mediaItem.MediaName,
                            isValid: true,
                        },
                        description: {
                            value: mediaItem.MediaDescription,
                            isValid: true,
                        },
                    },
                    true
                );
            } catch (err) {}
        };
        fetchMediaItem();
    }, [sendRequest, username, setFormData]);

    const mediaItemUpdateSubmitHandler = async (event) => {
        event.preventDefault();
        try {
            await sendRequest(
                `${process.env.REACT_APP_BACKEND_URL}/media/${username}/${mediaItemId}`,
                'PATCH',
                JSON.stringify({
                    title: formState.inputs.title.value,
                    description: formState.inputs.description.value,
                }),
                {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + auth.token,
                }
            );
            navigate(`/${username}/media`);
        } catch (err) {}
    };

    if (isLoading) {
        return (
            <div className="center">
                <LoadingSpinner asOverlay />
            </div>
        );
    }

    if (!loadedMediaItem && !error) {
        return (
            <div className="center">
                <Card>
                    <h2>Could not find media item!</h2>
                </Card>
            </div>
        );
    }

    return (
        <>
            <ErrorModal error={error} onClear={clearError} />

            {!isLoading && loadedMediaItem && (
                <form className="media-form" onSubmit={mediaItemUpdateSubmitHandler}>
                    <Input
                        id="title"
                        element="input"
                        type="text"
                        label="Title"
                        validators={[VALIDATOR_REQUIRE()]}
                        errorText="Please enter a valid title."
                        onInput={inputHandler}
                        initialValue={loadedMediaItem.MediaName}
                        initialValid={true}
                    />

                    <Input
                        id="description"
                        element="textarea"
                        label="Description"
                        validators={[VALIDATOR_MINLENGTH(3)]}
                        errorText="Please enter a valid description (min. 3 characters)."
                        onInput={inputHandler}
                        initialValue={loadedMediaItem.MediaDescription}
                        initialValid={true}
                    />

                    <Button type="submit" disabled={!formState.isValid}>
                        Update Item
                    </Button>
                </form>
            )}
        </>
    );
};

export default UpdateMedia;
