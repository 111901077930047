import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Input from '../../shared/components/FormElements/Input';
import Button from '../../shared/components/FormElements/Button';
import ErrorModal from '../../shared/components/UIElements/ErrorModal';
import LoadingSpinner from '../../shared/components/UIElements/LoadingSpinner';
import FileUpload from '../../shared/components/FormElements/FileUpload';
import {
    VALIDATOR_REQUIRE,
    VALIDATOR_MINLENGTH,
    VALIDATOR_MAXLENGTH,
} from '../../shared/util/validators';
import { useForm } from '../../shared/hooks/form-hook';
import { useHttpClient } from '../../shared/hooks/http-hook';
import { AuthContext } from '../../shared/context/auth-context';
import { Uploader } from '../../shared/util/multi-part-upload-handler';
import { formatFileName } from '../../shared/util/formatFileName';

import './MediaForm.css';
import './NewMedia.css';

const NewMedia = () => {
    const navigate = useNavigate();
    const auth = useContext(AuthContext);
    const { isLoading, setIsLoading, error, setError, sendRequest, clearError } =
        useHttpClient();
    const [fileUploadProgress, setFileUploadProgress] = useState(null);
    const [checkbox1, setCheckbox1] = useState(false);
    const [checkbox2, setCheckbox2] = useState(false);
    const [checkbox3, setCheckbox3] = useState(false);

    const [formState, inputHandler] = useForm(
        {
            title: {
                value: '',
                isValid: false,
            },
            description: {
                value: '',
                isValid: false,
            },
            file: {
                value: null,
                isValid: false,
            },
        },
        false
    );

    const checkboxStateHandler = (event) => {
        const target = event.target.name;

        if (target === 'checkbox1') {
            setCheckbox1(!checkbox1);
        }

        if (target === 'checkbox2') {
            setCheckbox2(!checkbox2);
        }

        if (target === 'checkbox3') {
            setCheckbox3(!checkbox3);
        }
    };

    const initializeMultiPartUpload = async (uniqueFilename) => {
        if (!formState.inputs.file.value) {
            setError('No file provided to uploader');
            return;
        }

        setIsLoading(true);

        const videoUploaderOptions = {
            fileName: uniqueFilename,
            file: formState.inputs.file.value,
            title: formState.inputs.title.value,
            description: formState.inputs.description.value,
        };

        let percentage = undefined;
        let uploader;

        uploader = new Uploader(videoUploaderOptions, auth.token);

        uploader
            .onProgress(({ percentage: newPercentage }) => {
                // to avoid the same percentage to be logged twice
                if (newPercentage !== percentage) {
                    percentage = newPercentage;
                    console.log(`${percentage}%`);

                    setFileUploadProgress(`File uploading. ${percentage}% complete`);
                }

                if (percentage >= 100) {
                    setIsLoading(false);
                    navigate(`/`);
                }
            })
            .onError((error) => {
                console.error(error);
                setError(
                    'Something went wrong uploading the file. Make sure you are on a wifi connection and try again.'
                );
            });

        uploader.start();

        // todo: uploader seems to restart when cancelling, need to find out how to prevent this
        // const cancelMultiPartUpload = () => {
        //     if (multiPartUploader) {
        //         multiPartUploader.abort();
        //         setFile(undefined);
        //     }
        // };
    };

    const checkFileUploadValidity = () => {
        let isValid = false;

        if (!formState.inputs.file.value) {
            setError(
                'No file provided to the uploader. Please select a valid image or video.'
            );
            return isValid;
        }

        if (!checkbox1 || !checkbox2 || !checkbox3) {
            setError('Please read and confirm all checkboxes before uploading.');
            return isValid;
        }

        if (!formState.inputs.title.isValid || !formState.inputs.description.isValid) {
            setError('Please provide a valid title and description.');
            return isValid;
        }

        isValid = true;

        return isValid;
    };

    const mediaSubmitHandler = async (event) => {
        event.preventDefault();

        const isValid = checkFileUploadValidity();

        if (!isValid) return;

        // what is the best naming convention here?
        // something like: username-mediaTitle-randomId.extension?
        const uniqueFilename = formatFileName(
            formState.inputs.file.value.name,
            auth.username,
            formState.inputs.title.value
        );

        const fileSizeInMb = formState.inputs.file.value.size / 1024 ** 2;

        // files >= 5mb should be handled with a multi-part upload
        if (fileSizeInMb >= 5) {
            await initializeMultiPartUpload(uniqueFilename);
            // navigate(`/${auth.username}/media`);
            return;
        }

        // files < 5mb do not require a multi-part upload, so we can just do a regular POST
        try {
            setFileUploadProgress(`File uploading. Please wait...`);

            const formData = new FormData();
            formData.append('title', formState.inputs.title.value);
            formData.append('description', formState.inputs.description.value);
            formData.append('filename', uniqueFilename);
            formData.append('file', formState.inputs.file.value);

            await sendRequest(
                `${process.env.REACT_APP_BACKEND_URL}/media`,
                'POST',
                formData,
                {
                    Authorization: 'Bearer ' + auth.token,
                }
            );

            navigate(`/`);
        } catch (err) {}
    };

    return (
        <>
            <ErrorModal error={error} onClear={clearError} />
            {isLoading && <LoadingSpinner asOverlay message={fileUploadProgress} />}

            <form className="media-form" onSubmit={mediaSubmitHandler}>
                <Input
                    id="title"
                    element="input"
                    type="text"
                    label="Title (required)"
                    validators={[
                        VALIDATOR_REQUIRE(),
                        VALIDATOR_MINLENGTH(3),
                        VALIDATOR_MAXLENGTH(20),
                    ]}
                    errorText="Please enter a valid title. Min 3 characters, max 20 characters."
                    onInput={inputHandler}
                />

                <Input
                    id="description"
                    element="textarea"
                    label="Description (required)"
                    validators={[VALIDATOR_MINLENGTH(3)]}
                    errorText="Please enter a valid description (at least 3 characters)."
                    onInput={inputHandler}
                />

                <FileUpload
                    id="file"
                    onInput={inputHandler}
                    // errorText="Please provide an image or video."
                />

                <hr></hr>

                <div className="file-upload-checkboxes">
                    <strong>
                        Please read and confirm all checkboxes before uploading:
                    </strong>

                    <label>
                        <input
                            name="checkbox1"
                            type="checkbox"
                            checked={checkbox1}
                            onChange={checkboxStateHandler}
                        />
                        Files uploaded must have been consented by the host team for
                        research and educational use, forms kept.
                    </label>

                    <label>
                        <input
                            name="checkbox2"
                            type="checkbox"
                            checked={checkbox2}
                            onChange={checkboxStateHandler}
                        />
                        Files should be unedited and not labelled. Only procedure name,
                        with no clinical data.
                    </label>

                    <label>
                        <input
                            name="checkbox3"
                            type="checkbox"
                            checked={checkbox3}
                            onChange={checkboxStateHandler}
                        />
                        I give permission for site admins to be able to see and download
                        my file. Access to your file is purely restricted to you and the
                        site admins, nobody else will be able to access your file.
                    </label>
                </div>

                <Button type="submit">Upload Media</Button>

                <br></br>
                <br></br>
                <div>
                    *Important*: Please make sure you are on a wifi connection before
                    uploading. Slow internet connections can cause upload failures.
                </div>
            </form>
        </>
    );
};

export default NewMedia;
