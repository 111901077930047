import './Footer.css';

const Footer = () => {
    return (
        <footer className="footer">
            <div className="footer-inner">
                <ul>
                    <li>
                        <a href="mailto:nirviduk@gmail.com">Contact us</a>
                    </li>
                </ul>

                <ul>
                    <li>
                        <i className="fas fa-globe fa-1x"></i> English (United Kingdom)
                    </li>
                </ul>

                <ul>
                    <li>&copy; NIR-VID 2023</li>
                </ul>
            </div>
        </footer>
    );
};

export default Footer;
