import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';

import NavLinks from './NavLinks';
import SideDrawer from './SideDrawer';
import Backdrop from '../components/UIElements/Backdrop';

import Logo from '../../assets/logo-cropped-darker.png';

import './MainNavigation.css';

const MainNavigation = () => {
    const [drawerIsOpen, setDrawerIsOpen] = useState(false);

    const openDrawerHandler = () => {
        setDrawerIsOpen(true);
    };

    const closeDrawerHandler = () => {
        setDrawerIsOpen(false);
    };

    return (
        <>
            {drawerIsOpen && <Backdrop onClick={closeDrawerHandler} />}

            <SideDrawer show={drawerIsOpen} onClick={closeDrawerHandler}>
                <nav className="main-navigation__drawer-nav">
                    <NavLinks />
                </nav>
            </SideDrawer>

            <header className="main-navigation--mobile">
                <div className="logo-container--mobile">
                    <a href="/">
                        <img className="logo-img" src={Logo} alt="logo"></img>
                    </a>

                    <NavLink to="/" className="logo-text">
                        Near Infrared Video
                        <div>NIR-VID</div>
                    </NavLink>
                </div>

                <button className="main-navigation__menu-btn" onClick={openDrawerHandler}>
                    <span />
                    <span />
                    <span />
                </button>
            </header>

            <header className="header">
                <div className="logo-container">
                    <a href="/">
                        <img className="logo-img" src={Logo} alt="logo"></img>
                    </a>

                    <NavLink to="/" className="logo-text">
                        Near Infrared Video
                        <div>NIR-VID</div>
                    </NavLink>
                </div>

                <NavLinks />
            </header>
        </>
    );
};

export default MainNavigation;
