import React, { useState } from 'react';

import Card from '../../shared/components/UIElements/Card';
import Input from '../../shared/components/FormElements/Input';
import Button from '../../shared/components/FormElements/Button';
import Modal from '../../shared/components/UIElements/Modal';
import ErrorModal from '../../shared/components/UIElements/ErrorModal';
import LoadingSpinner from '../../shared/components/UIElements/LoadingSpinner';
import { VALIDATOR_EMAIL, VALIDATOR_REQUIRE } from '../../shared/util/validators';
import { useForm } from '../../shared/hooks/form-hook';
import { useHttpClient } from '../../shared/hooks/http-hook';

import './Auth.css';

const ForgotPassword = () => {
    const [showModal, setShowModal] = useState(false);
    const { isLoading, error, setError, sendRequest, clearError } = useHttpClient();

    const [formState, inputHandler, setFormData] = useForm(
        {
            username: {
                value: '',
                isValid: false,
            },
            email: {
                value: '',
                isValid: false,
            },
        },
        false
    );

    const dismissModalHandler = () => {
        setShowModal(false);
    };

    const forgotPasswordSubmitHandler = async (event) => {
        event.preventDefault();

        try {
            await sendRequest(
                `${process.env.REACT_APP_BACKEND_URL}/auth/forgotPassword`,
                'POST',
                JSON.stringify({
                    username: formState.inputs.username.value,
                    email: formState.inputs.email.value,
                }),
                {
                    'Content-Type': 'application/json',
                }
            );

            setShowModal(true);

            // disable the submit button to prevent multiple requests
            setFormData(
                {
                    ...formState.inputs,
                    email: undefined,
                    passwordConfirm: undefined,
                },
                false
            );
        } catch (err) {
            console.log(err);
        }
    };

    return (
        <>
            <ErrorModal error={error} onClear={clearError} />

            <Modal
                show={showModal}
                onCancel={dismissModalHandler}
                header="Password reset request submitted"
                footerClass="media-item__modal-actions"
                footer={
                    <>
                        <Button onClick={dismissModalHandler}>OK</Button>
                    </>
                }
            >
                <p>Please check your email with instructions to reset your password.</p>

                <p>
                    Check your spam/junk folder if it does not appear in your main inbox.
                </p>
            </Modal>

            <Card className="authentication">
                {isLoading && <LoadingSpinner asOverlay />}

                <h2>Forgot password form</h2>
                <div>
                    Please enter your username and email. You will be sent an email with
                    the password reset instructions
                </div>

                <hr />

                <form onSubmit={forgotPasswordSubmitHandler}>
                    <Input
                        element="input"
                        id="username"
                        label="Username"
                        validators={[VALIDATOR_REQUIRE()]}
                        errorText="Please enter a valid username with at least 3 characters"
                        onInput={inputHandler}
                        autoComplete={'off'}
                    />

                    <Input
                        element="input"
                        id="email"
                        type="text"
                        label="Email"
                        validators={[VALIDATOR_EMAIL()]}
                        errorText="Please enter a valid email."
                        onInput={inputHandler}
                        autoComplete={'on'}
                    />

                    <Button type="submit" disabled={!formState.isValid}>
                        Submit
                    </Button>
                </form>
            </Card>
        </>
    );
};

export default ForgotPassword;
