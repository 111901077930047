import React, { useState } from 'react';

import Modal from '../shared/components/UIElements/Modal';
import CommitteeContent from './CommitteeContent';

import Manish from '../assets/committee/manish-chand.jpg';
import Luigi from '../assets/committee/luigi-bonnie.jpg';
import Antonino from '../assets/committee/antonino-spinelli.jpg';
import Delia from '../assets/committee/delia-cortes-guiral.jpg';
import Mohamed from '../assets/committee/mohamed-hassouna.jpg';

import './Committee.css';

const Committee = () => {
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [selectedTeamMember, setSelectedTeamMember] = useState('');
    const [selectedTeamMemberHeader, setSelectedTeamMemberHeader] = useState('');

    return (
        <>
            <Modal
                show={showConfirmModal}
                header={selectedTeamMemberHeader}
                footerClass="media-item__modal-actions"
                footer={
                    <div
                        className="committee-dismiss-modal"
                        onClick={() => setShowConfirmModal(false)}
                    >
                        Dismiss
                    </div>
                }
            >
                <CommitteeContent teamMember={selectedTeamMember} />
            </Modal>

            <div className="committee-container">
                <div className="committee-header">
                    <h1 className="committee-title">Executive Committee</h1>

                    <div className="divider"></div>
                </div>

                <div className="about-us-container">
                    <div className="about-us-body">
                        <div className="team-gallery">
                            <div className="team-img-container">
                                <img className="team-img" src={Manish} alt="manish"></img>

                                <div className="team-img-bio">
                                    <strong className="team-img-name">
                                        Prof Manish Chand (UK)
                                    </strong>

                                    <p>
                                        Chairman - Active member of several surgical
                                        societies, especially{' '}
                                        <a href="https://isfgs.org/" target="_blank">
                                            ISFGS
                                        </a>
                                        ,{' '}
                                        <a href="https://eaes.eu/" target="_blank">
                                            EAES
                                        </a>
                                        , and{' '}
                                        <a href="https://www.facs.org/" target="_blank">
                                            ACS
                                        </a>{' '}
                                        Technology committees.
                                    </p>

                                    <p
                                        className="bio-see-more"
                                        onClick={() => {
                                            setShowConfirmModal(true);
                                            setSelectedTeamMember('manish');
                                            setSelectedTeamMemberHeader(
                                                'Prof. Manish Chand (UK)'
                                            );
                                        }}
                                    >
                                        More details
                                    </p>
                                </div>
                            </div>

                            <div className="divider--committee"></div>

                            <div className="team-img-container">
                                <img className="team-img" src={Luigi} alt="luigi"></img>

                                <div className="team-img-bio">
                                    <strong className="team-img-name">
                                        Prof Luigi Bonnie (Italy)
                                    </strong>

                                    <p>
                                        The President of the EU chapter of the
                                        International Society of Image Guided Surgery.
                                    </p>

                                    <p
                                        className="bio-see-more"
                                        onClick={() => {
                                            setShowConfirmModal(true);
                                            setSelectedTeamMember('luigi');
                                            setSelectedTeamMemberHeader(
                                                'Prof. Luigi Bonnie (Italy)'
                                            );
                                        }}
                                    >
                                        More details
                                    </p>
                                </div>
                            </div>

                            <div className="divider--committee"></div>

                            <div className="team-img-container">
                                <img
                                    className="team-img"
                                    src={Antonino}
                                    alt="antonino"
                                ></img>

                                <div className="team-img-bio">
                                    <strong className="team-img-name">
                                        Prof Antonino Spinelli (Italy)
                                    </strong>

                                    <p>
                                        The General Secretary and President in waiting of
                                        European Society of Coloproctology,{' '}
                                        <a
                                            href="https://www.escp.eu.com/"
                                            target="_blank"
                                        >
                                            ESCP
                                        </a>
                                        .
                                    </p>

                                    <p
                                        className="bio-see-more"
                                        onClick={() => {
                                            setShowConfirmModal(true);
                                            setSelectedTeamMember('antonino');
                                            setSelectedTeamMemberHeader(
                                                'Prof. Antonino Spinelli (Italy)'
                                            );
                                        }}
                                    >
                                        More details
                                    </p>
                                </div>
                            </div>

                            <div className="divider--committee"></div>

                            <div className="team-img-container">
                                <img className="team-img" src={Delia} alt="delia"></img>

                                <div className="team-img-bio">
                                    <strong className="team-img-name">
                                        Prof Delia Cortés Guiral (Spain)
                                    </strong>

                                    <p>
                                        Member of{' '}
                                        <a
                                            href="https://www.essoweb.org/"
                                            target="_blank"
                                        >
                                            ESSO
                                        </a>{' '}
                                        Board of Directors. Member the Editorial Advisory
                                        Board of Colorectal Disease and member of the
                                        Media Editor Committee for Surgical Endoscopy
                                        Journal.
                                    </p>

                                    <p
                                        className="bio-see-more"
                                        onClick={() => {
                                            setShowConfirmModal(true);
                                            setSelectedTeamMember('delia');
                                            setSelectedTeamMemberHeader(
                                                'Prof. Delia Cortés Guiral (Spain)'
                                            );
                                        }}
                                    >
                                        More details
                                    </p>
                                </div>
                            </div>

                            <div className="divider--committee"></div>

                            <div className="team-img-container centered">
                                <img
                                    className="team-img"
                                    src={Mohamed}
                                    alt="mohamed"
                                ></img>

                                <div className="team-img-bio">
                                    <strong className="team-img-name">
                                        Dr. Mohamed Hassouna (UK)
                                    </strong>

                                    <p>
                                        Webmaster - Clinical Lecturer and an MSc programme
                                        leader at Division of Surgery and Interventional
                                        Science, University College London (UCL).
                                    </p>

                                    <p
                                        className="bio-see-more"
                                        onClick={() => {
                                            setShowConfirmModal(true);
                                            setSelectedTeamMember('mohamed');
                                            setSelectedTeamMemberHeader(
                                                'Dr. Mohamed Hassouna (UK)'
                                            );
                                        }}
                                    >
                                        More details
                                    </p>
                                </div>
                            </div>

                            <div className="divider--committee"></div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Committee;
