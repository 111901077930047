import React, { useContext } from 'react';
import { AuthContext } from '../shared/context/auth-context';

import './Home.css';

const Home = () => {
    const auth = useContext(AuthContext);

    return (
        <div className="homepage-container">
            <div className="homepage-body">
                <div className="hero-image">
                    <div className="hero-text">
                        <h1>Welcome to NIR-VID</h1>
                    </div>
                </div>

                {auth.isLoggedIn && (
                    <h3>
                        {/* <span>Hello {auth.displayName} </span> */}
                        {auth.isAdmin && <span>[admin user]</span>}
                    </h3>
                )}

                <section className="section-content">
                    <div className="homepage-section-container section-one">
                        <div className="homepage-section-text section-one">
                            <h3 className="homepage-section-header">Open access</h3>

                            <div className="divider"></div>

                            <p>
                                This is an online, open access resource for all
                                researchers in fluorescence guided surgery (FGS). We
                                provide access to anonymized, high-quality
                                gastrointestinal surgery videos using ICG in order for
                                researchers to further investigate the potential of this
                                novel technology.
                            </p>
                        </div>
                    </div>
                </section>

                <section className="section-content dark-bg">
                    <p className="homepage-header">Fluorescence guided surgery</p>

                    <div className="divider homepage-section-two"></div>

                    <div className="homepage-section-container">
                        {/* <img
                            className="homepage-section-img"
                            src={Section2Img}
                            alt="medical"
                        ></img> */}

                        <div className="homepage-section-text section-two">
                            <p>
                                Fluorescence guided surgery has become popularised over
                                recent years and spans a number of disciplines. At
                                NIR-VID, we concentrate on gastrointestinal surgery but
                                look forward to welcoming more specialties in the future.
                                The videos are voluntarily uploaded by surgical teams
                                across Europe and the wider community and ensure anonymity
                                and consent of patients&rsquo; data.
                            </p>
                        </div>

                        <div className="homepage-section-text section-two">
                            <p>
                                Researchers are able to submit research proposals to the
                                executive committee and gain access to video data for
                                their study. The review process is undertaken within two
                                weeks. Video data is securely stored on a secure
                                cloud-based system.
                                <br></br>
                                <br></br>
                                Please be aware that all uploads to this site can be
                                moderated and reviewed by the site admin.
                            </p>
                        </div>
                    </div>
                </section>

                <section className="section-content">
                    <div className="homepage-section-container publications-list-container">
                        <div className="homepage-section-text">
                            <h1>FGS related publications</h1>

                            <div className="divider"></div>

                            <div className="publications-list">
                                <div className="publications-list-item-container">
                                    <div className="publications-list-item publication-type">
                                        Journal article
                                    </div>

                                    <div className="publications-list-item publication-title">
                                        Preoperative tumor marking with indocyanine green
                                        prior of robotic colorectal resections
                                    </div>

                                    <div className="publications-list-item publication-link">
                                        <a
                                            target="_blank"
                                            rel="noreferrer"
                                            href="https://pubmed.ncbi.nlm.nih.gov/36620381"
                                        >
                                            Link to text
                                        </a>
                                    </div>
                                </div>

                                <div className="publications-list-item-container">
                                    <div className="publications-list-item publication-type">
                                        Journal article
                                    </div>

                                    <div className="publications-list-item publication-title">
                                        Use of fluorescence imaging and indocyanine green
                                        during colorectal surgery: Results of an
                                        intercontinental Delphi survey
                                    </div>

                                    <div className="publications-list-item publication-link">
                                        <a
                                            target="_blank"
                                            rel="noreferrer"
                                            href="https://pubmed.ncbi.nlm.nih.gov/36427929"
                                        >
                                            Link to text
                                        </a>
                                    </div>
                                </div>

                                <div className="publications-list-item-container">
                                    <div className="publications-list-item publication-type">
                                        Journal article
                                    </div>

                                    <div className="publications-list-item publication-title">
                                        Interobserver Variability in the Assessment of
                                        Fluorescence Angiography in the Colon
                                    </div>

                                    <div className="publications-list-item publication-link">
                                        <a
                                            target="_blank"
                                            rel="noreferrer"
                                            href="https://pubmed.ncbi.nlm.nih.gov/36377296"
                                        >
                                            Link to text
                                        </a>
                                    </div>
                                </div>

                                <div className="publications-list-item-container">
                                    <div className="publications-list-item publication-type">
                                        Journal article
                                    </div>

                                    <div className="publications-list-item publication-title">
                                        Fluorescence Guided Surgery-A New Era in Surgery
                                    </div>

                                    <div className="publications-list-item publication-link">
                                        <a
                                            target="_blank"
                                            rel="noreferrer"
                                            href="https://pubmed.ncbi.nlm.nih.gov/36136095"
                                        >
                                            Link to text
                                        </a>
                                    </div>
                                </div>

                                <div className="publications-list-item-container">
                                    <div className="publications-list-item publication-type">
                                        Journal article
                                    </div>

                                    <div className="publications-list-item publication-title">
                                        Fluorescence Imaging in Colorectal Surgery: An
                                        Updated Review and Future Trends
                                    </div>

                                    <div className="publications-list-item publication-link">
                                        <a
                                            target="_blank"
                                            rel="noreferrer"
                                            href="https://pubmed.ncbi.nlm.nih.gov/35232304"
                                        >
                                            Link to text
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    );
};

export default Home;
