import MainNavigation from './MainNavigation';
import Footer from './Footer';

import './Layout.css';

const Layout = (props) => {
    return (
        <div className="layout">
            <MainNavigation />
            <main className="main-container">{props.children}</main>
            <Footer />
        </div>
    );
};

export default Layout;
