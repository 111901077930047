import React, { useRef, useState, useEffect } from 'react';

import Button from './Button';
import './FileUpload.css';

const FileUpload = (props) => {
    const [file, setFile] = useState();
    const [previewUrl, setPreviewUrl] = useState();
    const [isValid, setIsValid] = useState(false);
    const filePickerRef = useRef();
    const videoPreviewRef = useRef();

    const { id, onInput, center, errorText } = props;

    useEffect(() => {
        if (!file) {
            return;
        }
        const fileReader = new FileReader();
        fileReader.onload = () => {
            setPreviewUrl(fileReader.result);
        };
        fileReader.readAsDataURL(file);
    }, [file]);

    useEffect(() => {
        videoPreviewRef.current?.load(); // necessary to update the video if a new one is selected
    }, [previewUrl]);

    const pickedHandler = (event) => {
        if (event.target.files.length === 0) return;

        let pickedFile;
        let fileIsValid = isValid;
        if (event.target.files && event.target.files.length === 1) {
            pickedFile = event.target.files[0];
            setFile(pickedFile);
            setIsValid(true);
            fileIsValid = true;
        } else {
            setIsValid(false);
            fileIsValid = false;
        }
        onInput(id, pickedFile, fileIsValid);
    };

    const pickImageHandler = () => {
        filePickerRef.current.click();
    };

    return (
        <div className="form-control">
            <input
                id={id}
                ref={filePickerRef}
                style={{ display: 'none' }}
                type="file"
                accept=".jpg, .jpeg, .webp, .heif, .png, .mp4, .mov, .webm"
                onChange={pickedHandler}
            />
            <div className={`file-upload ${center && 'center'}`}>
                <div className="file-upload__preview">
                    {previewUrl && file?.['type'].includes('image') && (
                        <img src={previewUrl} alt="Preview" />
                    )}

                    {previewUrl && file?.['type'].includes('video') && (
                        <video ref={videoPreviewRef} controls>
                            <source src={previewUrl} />
                        </video>
                    )}

                    {!previewUrl && <p>Please pick an image or video</p>}
                </div>

                <Button type="button" onClick={pickImageHandler}>
                    Select file
                </Button>
            </div>
            {!isValid && <p>{errorText}</p>}
        </div>
    );
};

export default FileUpload;
