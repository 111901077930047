import { v4 as uuidv4 } from 'uuid';

export const formatFileName = (originalname, username, mediaTitle) => {
    const randomString = uuidv4();
    const date = getDate();

    // include the original filename here?
    const newFileName = `${mediaTitle}-${username}-${date}-${randomString}`;

    // limit to 80 characters - is this necessary?
    return newFileName.substring(0, 80);
};

const getDate = () => {
    let today = new Date();
    return today.toISOString().split('T')[0];
};
