import { useState, useCallback, useEffect } from 'react';

let logoutTimer;
const timeToAutoLogout = 1000 * 60 * 60 * 24 * 7; // 7 days (in milliseconds)
const localStorageId = process.env.REACT_APP_LOCAL_STORAGE_ID;

export const useAuth = () => {
    const [token, setToken] = useState(false);
    const [tokenExpirationDate, setTokenExpirationDate] = useState();
    const [userId, setUserId] = useState(false);
    const [username, setUsername] = useState(false);
    const [displayName, setDisplayName] = useState(false);
    const [isAdmin, setIsAdmin] = useState(false);

    const login = useCallback(
        (uid, token, username, displayName, isAdmin, expirationDate) => {
            setToken(token);
            setUserId(uid);
            setUsername(username);
            setDisplayName(displayName);
            setIsAdmin(isAdmin);

            const tokenExpirationDate =
                expirationDate || new Date(new Date().getTime() + timeToAutoLogout);

            setTokenExpirationDate(tokenExpirationDate);

            localStorage.setItem(
                localStorageId,
                JSON.stringify({
                    userId: uid,
                    token: token,
                    username: username,
                    displayName: displayName,
                    isAdmin: isAdmin,
                    expiration: tokenExpirationDate.toISOString(),
                })
            );
        },
        []
    );

    const logout = useCallback(() => {
        setToken(null);
        setTokenExpirationDate(null);
        setUserId(null);
        localStorage.removeItem(localStorageId);

        window.location.replace('/');
    }, []);

    useEffect(() => {
        if (token && tokenExpirationDate) {
            const remainingTime = tokenExpirationDate.getTime() - new Date().getTime();

            logoutTimer = setTimeout(logout, remainingTime);
        } else {
            clearTimeout(logoutTimer);
        }
    }, [token, logout, tokenExpirationDate]);

    useEffect(() => {
        const storedData = JSON.parse(localStorage.getItem(localStorageId));

        // don't check for any variables that might be false here, because the login function will never run
        if (
            storedData &&
            storedData.token &&
            storedData.username &&
            storedData.userId &&
            storedData.displayName &&
            new Date(storedData.expiration) > new Date()
        ) {
            login(
                storedData.userId,
                storedData.token,
                storedData.username,
                storedData.displayName,
                storedData.isAdmin,
                new Date(storedData.expiration)
            );
        }
    }, [login]);

    return { token, login, logout, userId, username, displayName, isAdmin };
};
