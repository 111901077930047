import React, { useState, useContext } from 'react';

import Card from '../../shared/components/UIElements/Card';
import Button from '../../shared/components/FormElements/Button';
import Modal from '../../shared/components/UIElements/Modal';
import ErrorModal from '../../shared/components/UIElements/ErrorModal';
import LoadingSpinner from '../../shared/components/UIElements/LoadingSpinner';

import { AuthContext } from '../../shared/context/auth-context';
import { useHttpClient } from '../../shared/hooks/http-hook';

import './MediaItem.css';

const MediaItem = (props) => {
    const { isLoading, error, sendRequest, clearError } = useHttpClient();
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const auth = useContext(AuthContext);

    const {
        id,
        creator,
        title,
        description,
        fileLocation,
        presignedUrl,
        s3Filename,
        onDelete,
        fileType,
    } = props;

    const showDeleteWarningHandler = () => {
        setShowConfirmModal(true);
    };

    const cancelDeleteHandler = () => {
        setShowConfirmModal(false);
    };

    const confirmDeleteHandler = async () => {
        setShowConfirmModal(false);

        try {
            await sendRequest(
                `${process.env.REACT_APP_BACKEND_URL}/media/${auth.username}/${id}`,
                'DELETE',
                JSON.stringify({
                    filename: s3Filename,
                }),
                {
                    Authorization: 'Bearer ' + auth.token,
                    'Content-Type': 'application/json',
                }
            );

            onDelete(id);
        } catch (err) {}
    };

    return (
        <>
            <ErrorModal error={error} onClear={clearError} />

            <Modal
                show={showConfirmModal}
                onCancel={cancelDeleteHandler}
                header="Are you sure?"
                footerClass="media-item__modal-actions"
                footer={
                    <>
                        <Button inverse onClick={cancelDeleteHandler}>
                            CANCEL
                        </Button>

                        <Button danger onClick={confirmDeleteHandler}>
                            DELETE
                        </Button>
                    </>
                }
            >
                <p>Do you want to proceed and delete this item? This can't be undone.</p>
            </Modal>

            <li className="media-item">
                <Card className="media-item__content">
                    {isLoading && <LoadingSpinner asOverlay />}

                    <div className="media-item__file">
                        {fileType.includes('image') && (
                            <img src={presignedUrl} alt={title} />
                        )}

                        {fileType.includes('video') && (
                            <video controls controlsList="nodownload">
                                <source src={presignedUrl} />
                            </video>
                        )}
                    </div>

                    <div className="media-item__info">
                        <h2>{title}</h2>
                        <p>{description}</p>
                    </div>

                    <div className="media-item__actions">
                        {auth.username === creator && (
                            <Button to={`/media/update/${auth.username}/${id}`}>
                                Edit
                            </Button>
                        )}

                        {auth.username === creator && (
                            <Button danger onClick={showDeleteWarningHandler}>
                                Delete
                            </Button>
                        )}
                    </div>
                </Card>
            </li>
        </>
    );
};

export default MediaItem;
